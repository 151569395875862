@use "libs/styling/src/lib/variables" as *;

// our styles
@forward "libs/styling/src/lib/variables";
@forward "libs/styling/src/lib/overrides/_angular-material-custom";
@forward "libs/styling/src/lib/overrides/_ag-grid-custom";
@forward "libs/styling/src/lib/bootstrap-layout";
@forward "./style/marker-cluster";
@forward "./style/reset";
@forward "./style/layout";
@forward "libs/styling/src/lib/typography";
@forward "libs/styling/src/lib/forms";
@forward "libs/styling/src/lib/buttons";
@forward "./style/icons";
@forward "./style/navigation";
@forward "./style/grids";
@forward "./style/cards";
@forward "./style/components";
@forward "./style/overrides/general";
@forward "./style/common";
@forward "libs/styling/src/lib/modal";
@forward "./style/dropdown";

// third party
// @import url("#{$assets-path}/fonts/fonts.css"); // @todo make sure this gets bundled into single css file

//Set REM sizing
html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
}

.ngb-tp-input-container {
  width: 8em !important;
}
