@use "libs/styling/src/lib/variables" as *;

@media (min-width: $breakpoint-xs-lower) {
  [class*="col-"] {
    padding: 0.8rem;
  }

  .row {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}

@media (min-width: $breakpoint-sm-lower) {
  [class*="col-"] {
    padding: 0.8rem;
  }

  .row {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
}

@media (min-width: $breakpoint-md-lower) {
  [class*="col-"] {
    padding: 1.2rem;
  }

  .row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

@media (min-width: $breakpoint-lg-lower) {
  [class*="col-"] {
    padding: 1.2rem;
  }

  .row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
  }
}

@media (min-width: $breakpoint-xl-lower) {
  [class*="col-"] {
    padding: 1.6rem;
  }

  .row {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}

.row-no-padding {
  [class*="col-"] {
    padding: 0;
  }
}

.row-no-margin {
  margin: 0;
}