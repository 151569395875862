@use "libs/styling/src/lib/mixins" as mixins;
@use "libs/styling/src/lib/variables" as *;

.icon {
  @include mixins.transition(all 0.2s ease-out);

  display: inline-block;
  width: 3.2rem;
  height: 3.2rem;
  fill: $color-verisk-white;

  &.xs {
    height: 1rem;
    width: 1rem;
  }

  &.extra-small {
    max-height: 2rem;
    max-width: 2rem;
  }

  &.smaller {
    height: 1.8rem;
    width: 1.8rem;
  }

  &.small {
    height: 2.2rem;
    width: 2.2rem;
  }

  &.medium {
    height: 2.6rem;
    width: 2.6rem;
  }

  &.large {
    height: 5rem;
    width: 5rem;
  }

  &.xl {
    height: 8rem;
    width: 8rem;
  }

  &.rounded {
    border-radius: 100% !important; // note `!important` used purely to override bootstrap
    fill: $color-white;
    stroke: $color-label-grey;
    background-color: $color-label-grey;
    transition: background-color 0.2s ease-out, stroke 0.2s ease-out;

    &:hover {
      fill: $color-white;
      stroke: $color-blue;
      background-color: $color-blue;
    }
  }

  &.active.rounded {
    fill: $color-white;
    background-color: $color-blue;
  }

  &.active {
    fill: $color-verisk-action-blue;
  }
}

%upper-menu-icon {
  @include mixins.transition(all 0.2s ease-out);

  background-color: $color-maplecroft-light-grey;

  &:hover {
    background-color: $color-blue;
  }
}
