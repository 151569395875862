.no-size {
  height: 0;
  width: 0;
  padding: 0;
  border: 0;
}

.flex-grow-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}