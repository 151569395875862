// Overrides and additonal styles for Maplecroft themed dropdowns
// which cannot be achieved with simple theming support.
@use "libs/styling/src/lib/variables" as *;

.typeahead-dropdown-container {
  .dropdown-menu {
    &.show {
      width: 30rem;
      max-height: 300px;
      overflow: auto;
      padding: $padding-1;
      font-size: $font-size-1;
      z-index: 1001;

      .dropdown-item {
        white-space: normal;
      }
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: $color-maplecroft-light-grey;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $padding-1;
      background-color: $color-grey;
    }
  }

  &.has-footer-row {
    .dropdown-menu {
      &.show {
        .dropdown-item:last-child {
          cursor: default;

          &.active,
          &:active,
          &:hover {
            background-color: $color-white;
          }
        }
      }
    }
  }

  &.shows-no-data-found {
    .dropdown-menu {
      &.show {
        .dropdown-item:first-child {
          cursor: default;
          font-style: italic;

          &.active,
          &:active,
          &:hover {
            background-color: $color-white;
          }
        }
      }
    }
  }

  .sub-container {
    width: $margin-2;
    margin-right: $margin-1;
  }
}
