@use "libs/styling/src/lib/overrides/ag-grid-custom-alpine" as *;
@use "libs/styling/src/lib/overrides/ag-grid-custom-balham" as *;
@use "libs/styling/src/lib/variables" as *;
@use "libs/styling/src/lib/placeholders/forms" as *;

.ag-theme-alpine {
  font-size: 1.2rem;
  font-family: $font-family-1;

  .ag-icon-none {
    color: $color-label-grey;
  }

  .ag-row {
    font-size: 1.2rem;
  }

  .ag-row-hover {
    background-color: $color-verisk-action-blue-10;
  }

  .mc-scores-header-with-weights {
    max-height: 53px;
    height: 54px;
  }

  .mc-unpadded-header-cell {
    padding-left: 0;
    padding-right: 0;
  }

  .mc-unpadded-cell {
    padding: 0;
  }

  .ag-overlay-no-rows-wrapper {
    background-color: #fff;
    margin-top: 48px;
    pointer-events: all;
  }

  .ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
    overflow-y: auto;
  }
  /* stylelint-disable property-no-vendor-prefix */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(0 0 0 / 50%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }

  &.mc-ag-grid-no-stripes {
    .ag-row {
      background-color: #fff;
    }
  }

  .mc-ag-group-full-width {
    .ag-group-value {
      width: 100%;
    }
  }
}

.ag-theme-alpine,
.ag-theme-datatable,
.ag-theme-scorecard {
  // Checkboxes
  .ag-checkbox-input-wrapper {
    @extend %relative;

    &:active,
    &:focus-within {
      box-shadow: none;
    }

    &::after {
      @extend %absolute;
    }

    &.ag-checked::after {
      @extend %absolute;
    }

    &.ag-indeterminate::after {
      @extend %checkbox-small;
      @extend %checkbox-indeterminate;
      @extend %absolute;
    }
  }

  .ag-group-checkbox {
    width: $padding-3;
  }
}

// AG GRID Sort Icon Colors
.icon-ag-sort-fill {
  fill: $color-verisk-ext-blue-1;
}

.icon-ag-sort-stroke {
  stroke: $color-verisk-ext-blue-1;
}

.icon-ag-sort-background-color {
  background-color: $color-verisk-action-blue-10;
}

.icon-ag-unsort-fill {
  fill: $color-verisk-action-blue;
}

.icon-ag-unsort-stroke {
  stroke: $color-verisk-action-blue;
}
