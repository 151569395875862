@use "libs/styling/src/lib/variables" as *;

.center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.mc-loading-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mc-faux-link {
  cursor: pointer;
  color: $color-verisk-action-blue-10;

  &:hover {
    color: $color-verisk-action-blue-10;
  }

  &.inactive {
    pointer-events: none;
    color: $color-label-grey;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ngb-dp-body {
  z-index: 1060 !important;
}
