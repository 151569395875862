@use "libs/styling/src/lib/variables" as *;
@use "libs/styling/src/lib/placeholders/animate" as *;

%icon-size-1 {
  height: $spacing-1;
  width: $spacing-1;
  min-width: $spacing-1;
}

%icon-size-2 {
  height: $spacing-2;
  width: $spacing-2;
  min-width: $spacing-2;
}

%icon-size-3 {
  height: $spacing-3;
  width: $spacing-3;
  min-width: $spacing-3;
}

%icon-size-4 {
  height: $spacing-4;
  width: $spacing-4;
  min-width: $spacing-4;
}

%icon-size-5 {
  height: $spacing-5;
  width: $spacing-5;
  min-width: $spacing-5;
}

%icon-size-6 {
  height: $spacing-6;
  width: $spacing-6;
  min-width: $spacing-6;
}

%icon-size-8 {
  height: $spacing-8;
  width: $spacing-8;
  min-width: $spacing-8;
}

%icon-size-10 {
  height: $spacing-10;
  width: $spacing-10;
  min-width: $spacing-10;
}

%icon-dropdown-size {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
}

%icon-chevron-size-1 {
  @extend %icon-size-1;
}

%icon-chevron-size-2 {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
}

%icon-cross-size {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
}

%icon-rotate {
  @extend %icon-size-2;
  @extend %rotate;
}

%search-icon {
  @extend %icon-size-2;

  margin-right: $margin-1;
  background: #fff url(#{$img-icon-path}/search.svg) no-repeat center center;
  background-size: $spacing-2;
}

%cross-icon {
  @extend %icon-size-2;

  cursor: pointer;
  background: #fff url(#{$img-icon-path}/close-blue.svg) no-repeat center center;
  background-size: 1rem;

  &:hover {
    background: #fff url(#{$img-icon-path}/close-ext-blue.svg) no-repeat center center;
    background-size: 1rem;
  }
}

%icon-close {
  height: 1.2rem;
  width: 1.2rem;
  min-width: 1.2rem;
  background: url(#{$img-icon-path}/close-white.svg) no-repeat 98% center;
}

%icon-padlock-white {
  @extend %icon-size-2;

  background: #fff url(#{$img-icon-path}/padlock-white.svg) no-repeat center center;
  background-size: $spacing-1;
}

%icon-dropdown {
  @extend %icon-size-2;

  transition: transform 0.25s;

  &:hover,
  &.show {
    transform: rotate(180deg);
  }
}

%icon-no-data-indices {
  background: #fff url(#{$img-icon-path}/no-data-indices.svg) no-repeat center center;
  height: $spacing-6;
  width: 3.8rem;
}

%icon-no-data-locations {
  background: #fff url(#{$img-icon-path}/no-data-locations.svg) no-repeat center center;
  height: $spacing-6;
  width: 3.8rem;
}

%icon-error {
  @extend %icon-size-2;

  background: url(#{$img-path}/icons/alert-circle.svg) no-repeat 98% center;
}

%icon-bin-circle {
  --color-icon-fill-primary: #{$color-verisk-black-50};
}

%icon-warning {
  --color-icon-fill-primary: #{$color-verisk-behavioural-gold};
}

%icon-cross-color-background {
  --color-icon-stroke-secondary: #{$color-white};

  &:hover,
  &:focus {
    --color-icon-stroke-secondary: #{$color-verisk-behavioural-gold};
  }
}

%icon-download-color-background {
  --color-icon-fill-primary: #{$color-white};

  &:hover,
  &:focus {
    --color-icon-fill-primary: #{$color-verisk-behavioural-gold};
  }
}

%icon-tick-circle-color {
  --color-icon-fill-primary: #{$color-verisk-behavioural-green};
  --color-icon-stroke-primary: #{$color-verisk-behavioural-green};
}

%icon-play-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
}

%icon-play-color-inverted {
  --color-icon-fill-primary: #{$color-white};
}

%icon-chevron-circle-color {
  --color-icon-fill-secondary: #{$color-verisk-action-blue};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-secondary: #{$color-white};
}

%icon-chevron-circle-color-inverted {
  --color-icon-fill-secondary: #{$color-white};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-secondary: #{$color-verisk-action-blue};
}

%icon-info-circle-color {
  --color-icon-fill-primary: #{$color-verisk-ext-blue-1};
  --color-icon-stroke-primary: #{$color-verisk-ext-blue-1};
  --color-icon-fill-secondary: #{$color-white};
}

%icon-info-circle-color-inverted {
  --color-icon-fill-primary: #{$color-white};
  --color-icon-stroke-primary: #{$color-verisk-ext-blue-1};
  --color-icon-fill-secondary: #{$color-verisk-ext-blue-1};
}

%icon-info-circle-action-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-white};
}

%icon-new-window-color-inverted {
  --color-icon-fill-primary: #{$color-white};
}

%icon-subnational-circle-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-white};
}

%icon-subnational-circle-color-inverted {
  --color-icon-fill-primary: #{$color-white};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-verisk-action-blue};
}

%icon-chevron-color {
  --color-icon-fill-primary: #{$color-verisk-black};
}

%icon-chevron-color-inverted {
  --color-icon-fill-primary: #{$color-white};
}

%icon-sort-color {
  --color-icon-fill-primary: #{$color-verisk-ext-blue-1};
  --color-icon-stroke-primary: #{$color-verisk-ext-blue-1};
  --color-icon-background-primary: #{$color-verisk-action-blue-10};
}

%icon-unsort-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-background-primary: transparent;
}

%icon-exclamation-circle-color {
  --color-icon-fill-primary: #{$color-verisk-behavioural-red};
  --color-icon-fill-secondary: #{$color-verisk-white};
}

%icon-exclamation-circle-color-inverted {
  --color-icon-fill-primary: #{$color-white};
  --color-icon-fill-secondary: #{$color-verisk-behavioural-red};
}

%icon-exclamation-circle-color-info {
  --color-icon-fill-primary: #{$color-white};
  --color-icon-fill-secondary: #{$color-verisk-action-blue};
}

%icon-cross-color {
  --color-icon-stroke-secondary: #{$color-verisk-action-blue};
}

%icon-cross-color-inverted {
  --color-icon-stroke-secondary: #{$color-verisk-white};
}

%icon-folder-tools-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-verisk-ext-blue-1};
  --color-icon-fill-tertiary: #{$color-verisk-action-blue-10};
}

%icon-line-chart-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
}

%icon-txt-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
  --color-icon-stroke-primary: #{$color-verisk-white};
  --color-icon-fill-secondary: #{$color-verisk-action-blue};
}

%icon-globe-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
}

%icon-share-link-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
}

%icon-sliders-color-inverted {
  --color-icon-stroke-primary: #{$color-verisk-white};
}

%icon-minimize-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
}

%icon-expand-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
}

%icon-question-circle-color {
  --color-icon-fill-primary: #{$color-verisk-action-blue};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-verisk-white};
}

%icon-question-circle-color-2 {
  --color-icon-fill-primary: #{$color-verisk-ext-blue-1};
  --color-icon-stroke-primary: #{$color-verisk-ext-blue-1};
  --color-icon-fill-secondary: #{$color-verisk-white};
}

%icon-question-circle-color-inverted {
  --color-icon-fill-primary: #{$color-verisk-white};
  --color-icon-stroke-primary: #{$color-verisk-action-blue};
  --color-icon-fill-secondary: #{$color-verisk-action-blue};
}

%icon-folder-empty-color {
  --color-icon-fill-primary: #{$color-verisk-ext-blue-1};
  --color-icon-fill-secondary: #{$color-verisk-action-blue-10};
}

%icon-arrow-curve-right-color {
  --color-icon-fill-tertiary: #{$color-verisk-action-blue};
}
