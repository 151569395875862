@use "libs/styling/src/lib/variables" as *;
@use "libs/styling/src/lib/mixins" as mixins;

.card {
  display: block;
  position: relative;
  margin: $padding-1;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;

  > .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    > .extract {
      padding: 0 $padding-1;
    }

    > .summary {
      position: absolute;
      bottom: 0;
      padding-top: $padding-1;
      padding-bottom: 12px;
      width: 100%;

      > .content-type {
        color: $color-verisk-action-blue;
        padding-left: $padding-1;
      }
    }

    .bookmark-button {
      z-index: 1050;
      position: absolute;
      right: 5px;
      bottom: 5px;

      > a {
        > .icon {
          display: block;
          fill: transparent;
          stroke: $color-verisk-action-blue;
          stroke-width: 2px;
        }

        &.bookmarked > .icon {
          fill: $color-verisk-action-blue;
        }
      }
    }
  }

  .mask {
    @include mixins.gradient-y(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1), 60%, 100%);

    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
  }
}

.card.nav {
  display: inline-block;
  width: 200px;
  height: 220px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  background-color: $color-white;

  > .container {
    > .header {
      background-color: $color-verisk-ext-blue-1;
      height: 100px;
      text-align: center;

      > .icon {
        fill: $color-white;
        stroke: transparent;
      }
    }

    > .details {
      padding: 10px 0 0;
      text-align: center;

      > p {
        padding: 0 15px;
      }
    }
  }
}

.card.content {
  border: 1px solid $color-maplecroft-light-grey;
  background-color: $color-white;

  &:hover {
    border-color: $color-verisk-action-blue;
  }

  > .container {
    display: flex;
    flex-direction: column;
    min-height: 386px;

    > .icon {
      position: absolute;
    }

    > .summary {
      background-color: #fff;
    }

    > .lead-tag {
      text-transform: uppercase;
      color: $color-verisk-action-blue;
      position: relative;
      padding: $padding-2 $padding-2 0 $padding-2;
      min-height: 48px;
    }

    .bookmark-button {
      position: absolute;
      right: 5px;
      top: 7px;
    }

    > .extract {
      flex: 1 0 auto;
      padding: 0 $padding-2;
      min-height: 125px;

      &:last-child {
        padding-bottom: 15px;
      }

      /* stylelint-disable property-no-vendor-prefix */
      /* stylelint-disable value-no-vendor-prefix */
      h3 {
        min-height: 85px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      > h6 {
        font-size: 1.4rem;
        font-weight: normal;
        margin: 15px 0;
        color: $color-verisk-black-85;
      }

      > .content-body {
        height: 125px;

        &::after {
          content: "";
          position: absolute;
          bottom: 80px;
          height: 100px;
          right: 0;
          width: 100%;
          background: linear-gradient(180deg, rgb(255 255 255 / 0%), rgb(255 255 255 / 0%) 20%, rgb(255 255 255 / 80%) 70%, #fff 95%);
        }

        ul {
          list-style: none;
          padding-left: 0; // P2-600: unwanted padding in content body `ul`s.
          -webkit-margin-start: 0;
          -webkit-padding-start: 0;
        }
      }

      > .content-image-container {
        overflow-y: hidden;
        height: 125px;

        > .content-image {
          background-size: cover;
          background-position: 50% center;
          width: 100%;
          padding-bottom: 125px;
        }
      }
    }

    > .mask {
      bottom: 80px;
    }
  }

  .mask {
    @include mixins.gradient-y(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), 60%, 100%);
  }
}
