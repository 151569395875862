@use "libs/styling/src/lib/variables" as *;
@use "libs/styling/src/lib/placeholders/layout" as *;

%caption {
  font-weight: $font-weight-light;
  font-size: $font-size-0;
  font-style: italic;
}

%lh-16 {
  line-height: 1.6rem;
}

%lh-19 {
  line-height: 1.9rem;
}

%lh-21 {
  line-height: 2.1rem;
}

%lh-22 {
  line-height: 2.2rem;
}

%lh-24 {
  line-height: 2.4rem;
}

%lh-26 {
  line-height: 2.6rem;
}

%lh-28 {
  line-height: 2.8rem;
}

%lh-30 {
  line-height: 3rem;
}

%lh-34 {
  line-height: 3.4rem;
}

%lh-46 {
  line-height: 4.6rem;
}

%overflow-hidden {
  overflow: hidden;
}

%ellipsis {
  text-overflow: ellipsis;
}

%nowrap {
  white-space: nowrap;
}

%slider-label {
  @extend %flex-row;
  @extend %align-center;
  @extend %nowrap;

  font-size: $font-size-1;
  font-weight: $font-weight-regular;
  color: $color-verisk-black-85;
}

%font-size-0 {
  font-size: $font-size-0;
}

%font-size-1 {
  font-size: $font-size-1;
}

%font-size-2 {
  font-size: $font-size-2;
}

%font-size-3 {
  font-size: $font-size-3;
}

%font-size-4 {
  font-size: $font-size-4;
}

%font-size-5 {
  font-size: $font-size-5;
}

%font-size-7 {
  font-size: $font-size-7;
}

%uppercase {
  text-transform: uppercase;
}

%capitalize {
  text-transform: capitalize;
}

%lowercase {
  text-transform: lowercase;
}

%bold {
  font-weight: 700;
}

%regular {
  font-weight: 400;
}

%medium {
  font-weight: 500;
}
