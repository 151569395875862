@use "@ag-grid-community/core/dist/styles/ag-grid" as *;
@use "@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin" as ag-grid-mixin;
@use "libs/styling/src/lib/variables" as *;

.ag-theme-alpine {
  @include ag-grid-mixin.ag-theme-alpine((
    header-foreground-color: $color-verisk-black-85,
    secondary-foreground-color: $color-verisk-black-85,
    foreground-color: $color-verisk-black-85,
    data-color: $color-verisk-black-85,
    alpine-active-color: $color-verisk-action-blue,
    header-background-color: $color-verisk-black-5,
    border-color: $color-verisk-black-20
  ));

  .ag-group-expanded {
    margin-right: 20px !important;
  }

  .ag-group-contracted {
    margin-right: 20px !important;
  }
}
