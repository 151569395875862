@use "libs/styling/src/lib/overrides/angular-material-deeppurple" as *;
@use "libs/styling/src/lib/variables" as *;
@use "libs/styling/src/lib/placeholders/typography" as *;
@use "libs/styling/src/lib/placeholders/border" as *;

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: $z-index-dropdown !important;
}

.cdk-overlay-pane {
  width: 300px !important;
  height: min-content;
  max-height: 600px !important;
  margin-top: $margin-1;
}

.mat-autocomplete-panel {
  width: 300px;
  height: min-content;
  max-height: 600px !important;
  padding: $padding-1;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-infix {
  width: 100% !important;
  padding: 0 !important;
  border: 0 !important;
  position: unset !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-input-element.ng-invalid {
  border: none !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: $border-radius !important;
  border: 1px solid $color-verisk-black-20 !important;
  background-color: white !important;
  padding: 1rem 3.2rem !important;
  font-size: 1.4rem;
  height: 3.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: $color-verisk-black-85;
  box-shadow: none;

  &:focus,
  &:hover,
  &:focus-within {
    border-color: $color-verisk-action-blue !important;
    color: $color-verisk-black-85 !important;
    background-color: $color-white !important;
    box-shadow: none !important;
  }

  &.invalid {
    border-color: $color-verisk-behavioural-red;
  }
}

.mat-optgroup-label {
  @extend %lh-21;
  @extend %border-bottom;

  font-size: $font-size-2 !important;
  font-weight: $font-weight-medium !important;
  color: $color-verisk-ext-blue-1 !important;
  margin: 0;
  padding: $padding-1 $padding-1 $padding-1 0 !important;
  display: flex !important;
  align-items: center;
  height: 4rem !important;
  line-height: 2.1rem !important;

  &:first-of-type {
    padding-top: 0 !important;
  }
}

.mat-option {
  padding: 0 $padding-1 !important;
  height: 3.2rem !important;
  line-height: 2.1rem !important;

  &:first-of-type {
    margin-top: 0.8rem !important;
  }
}

.mat-option-text {
  font-size: $font-size-2 !important;
  line-height: 2.1rem !important;
}

.mat-optgroup .mat-option:not(.mat-option-multiple) {
  padding-left: $padding-1;
}
