@use "ag-grid-community/dist/styles/ag-grid" as *;
@use "ag-grid-community/dist/styles/ag-theme-balham" as *;
@use "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin" as *;

.ag-theme-datatable,
.ag-theme-scorecard {
  @include ag-theme-balham((
    grid-size: 4px,
    background-color: #fff,
    odd-row-background-color: null,
    font-family: unset
  ));
}
