@use "libs/styling/src/lib/variables" as *;
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// We import bootstrap mixins into here, but bootstrap 4.6.1 doesn't support the new SASS @use and @forward syntax
// so we also need to import the bootstrap functions and variables first for the mixins. But these variables can conflict with
// our variable names, so when we import this mixin file into another SASS file, import it with a namespace to avoid
// this variable conflict. E.g. @import "libs/styling/src/lib/mixins" as mixins;

@mixin font-size($size) {
  font-size: $size;
}

@mixin content-block-width($cols: 1) {
  flex: calc(100% / #{$cols});
  max-width: calc(100% / #{$cols});
}

// grid framework (currently uses bootstrap mixins)

// make grid
@mixin make-grid() {
  @include make-row;

  margin: 0;
}

// make column
@mixin make-column($xs: 0, $sm: 0, $md: 0, $lg: 0, $xl: 0) {
  @include make-col-ready;

  @if $xs > 0 {
    @include media-breakpoint-up(xs) {
      @include make-col($xs);
    }
  } @else {
    @include make-col($grid-columns);
  }

  @if $sm > 0 {
    @include media-breakpoint-up(sm) {
      @include make-col($sm);
    }
  }

  @if $md > 0 {
    @include media-breakpoint-up(md) {
      @include make-col($md);
    }
  }

  @if $lg > 0 {
    @include media-breakpoint-up(lg) {
      @include make-col($lg);
    }
  }

  @if $xl > 0 {
    @include media-breakpoint-up(xl) {
      @include make-col($xl);
    }
  }

  padding: 0;

  // NOTE: include `box-sizing: border-box;` to enable default padding
}

/* stylelint-disable property-no-vendor-prefix */
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin ie10-11-only {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin modal-small-screen {
  @media (max-width: 799px) {
    @content;
  }
}

@mixin modal-mobile-screen {
  @media (max-width: 399px) {
    @content;
  }
}

@mixin padding() {
  @media (min-width: $breakpoint-xs-lower) {
    padding: calc(#{$padding-2} / 2);
  }

  @media (min-width: $breakpoint-sm-lower) {
    padding: calc(#{$padding-2} / 2);
  }

  @media (min-width: $breakpoint-md-lower) {
    padding: calc(#{$padding-3} / 2);
  }

  @media (min-width: $breakpoint-lg-lower) {
    padding: calc(#{$padding-3} / 2);
  }

  @media (min-width: $breakpoint-xl-lower) {
    padding: calc(#{$padding-4} / 2);
  }
}
