@use 'components/attribution' as *;
@use 'libs/styling/src/lib/variables' as *;

body.mc-no-scroll {
  overflow: hidden;
}

.written-content-link {
  cursor: pointer;
  color: $color-verisk-action-blue !important;

  &:hover {
    color: $color-verisk-action-blue !important;
  }
}
